import React from "react";
import SEO from "../components/common/seo";
import {graphql, Link} from "gatsby";
import LayoutFourth from "../components/common/layout-fourth";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import Markdown from "react-markdown";
import {customElements} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const WebinarPage = ({pageContext, data}) => {
    const lang = pageContext.locale.path.default;
    const webinar = data.strapiWebinar;
    const footer = data.footer.frontmatter[lang];
    const share = async (event) => {
        event.preventDefault();

        if (typeof window !== 'undefined') {
            const url = window.location.href;
            let shareUrl = '';

            switch (event.currentTarget.dataset.rrss) {
                case 'facebook':
                    shareUrl = `http://www.facebook.com/sharer.php?u=${url}`;
                    break;
                case 'twitter':
                    shareUrl = `http://twitter.com/share?text=${webinar.name}&url=${url}&hashtags=tecalis`;
                    break;
                case 'linkedin':
                    shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
                    break;
                case 'instagram':
                    shareUrl = `https://www.instagram.com/?url=${url}`;
                    break;
            }

            window.open(shareUrl, '_blank');
        }
    }

    function initializeHubspotForm() {
        if (typeof window !== 'undefined' && 'hbspt' in window) {
            const form = webinar.is_finished ? webinar.hubspot_form_recorded : webinar.hubspot_form_registration;

            window.hbspt.forms.create({
                region: form.region,
                portalId: form.portal_id,
                formId: form.form_id,
                target: "#rad-form-container",
                onFormReady: (form) => {
                    form.find('input[name="webinar_name"]').val(webinar.hubspot_webinar_name).change();
                }
            });
        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }

    initializeHubspotForm();

    return (
        <LayoutFourth pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={webinar.seo.title} description={webinar.seo.meta_description}/>

            <header id="header" className="rad-header mb-0">
                <div className="container text-center" style={{minHeight: 442}}>
                    <StaticImage src={"../img/brand.png"} alt="Tecalis" width={160} height={39} className="mt-10"/>
                    <h1 className="mt-9">{webinar.name}</h1>

                    <div className="row d-md-none">
                        <div className="col-md-8 offset-md-2">
                            <hr className="my-8" style={{borderColor: "#DEE2E6"}}/>
                        </div>
                    </div>

                    {!webinar.is_finished && <div className="pb-10 px-5 pb-md-0 px-md-0">
                        <span className="d-block d-sm-inline-block mb-5 mb-sm-0 text-left text-sm-center">
                            <StaticImage src="../img/ic_webinar_calendar.svg" alt="Date" className="align-top mr-3" width={27} height={30}/>
                            <span className="webinar-time align-top mr-sm-8">{webinar.date}</span>
                        </span>

                        <div className="d-block d-sm-inline-block text-left text-sm-center">
                            <StaticImage src="../img/ic_webinar_clock.svg" alt="Time" className="align-top mr-3" width={30} height={30}/>
                            <span className="webinar-time align-top">{webinar.time_start.substring(0, 5)} - {webinar.time_end.substring(0, 5)}</span>
                        </div>
                    </div>}

                    {webinar.is_finished && <div className="pb-10 px-5 pb-md-0 px-md-0">
                        <span className="d-block d-sm-inline-block mb-5 mb-sm-0 text-center">
                            <span className="webinar-time align-top">{webinar.recorded_text}</span>
                        </span>
                    </div>}
                </div>
            </header>

            <section className="exhibitors">
                <div className="container">
                    <div className="row justify-content-center">
                        {webinar.exhibitors.map((exhibitor, i) => {
                            return <div className="col-lg-2 col-md-3 col-4 exhibitor-item" key={i}>
                                <GatsbyImage alt={`${exhibitor.first_name} ${exhibitor.last_name}`} image={getImage(exhibitor.photo.localFile)} className="img-fluid rounded-circle"/>
                                <h5>{`${exhibitor.first_name} ${exhibitor.last_name}`}</h5>
                            </div>
                        })}
                    </div>
                </div>
            </section>

            <section className="rad-form-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-12 mb-lg-0 order-lg-6">
                            <div id="rad-form-container"/>
                        </div>

                        <div className="col-lg-5 order-lg-2">
                            <h2>{webinar.title}</h2>

                            <Markdown children={webinar.description} rehypePlugins={[rehypeRaw]} components={customElements('rad-p')} />

                            {webinar.photo && <GatsbyImage alt={webinar.title} image={getImage(webinar.photo.localFile)} className="img-fluid"/>}
                        </div>

                        <div className="col-lg-1 order-lg-1 d-flex flex-lg-column justify-content-center justify-content-lg-start align-items-center mt-8 mt-lg-0">
                            <OutboundLink href="#" onClick={share} data-rrss="facebook" target="_blank" className="webinar-share-item" rel="noopener noreferrer me">
                                <i className="fe fe-facebook"/>
                            </OutboundLink>

                            <OutboundLink href="#" onClick={share} data-rrss="twitter" target="_blank" className="webinar-share-item" rel="noopener noreferrer me">
                                <i className="fe fe-twitter"/>
                            </OutboundLink>

                            <OutboundLink href="#" onClick={share} data-rrss="linkedin" target="_blank" className="webinar-share-item" rel="noopener noreferrer me">
                                <i className="fe fe-linkedin"/>
                            </OutboundLink>

                            <OutboundLink href="#" onClick={share} data-rrss="instagram" target="_blank" className="webinar-share-item" rel="noopener noreferrer me">
                                <i className="fe fe-instagram"/>
                            </OutboundLink>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-10">
                <div className="container">
                    <div className="rad-concept">
                        <h3>{webinar.cta_title}</h3>

                        <Markdown children={webinar.cta_description} rehypePlugins={[rehypeRaw]}/>

                        <div className="mt-5 text-center">
                            <a href="#header" className="btn-webinar d-inline-block smooth-scroll">{webinar.cta_button}</a>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="rad-footer">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-lg-auto">
                            <StaticImage src={"../img/brand.png"} alt="Tecalis" width={103} height={25} className="tecalis-logo"/>

                            <p>
                                © {new Date().getFullYear()} Tecalis. {footer.copyright}<br/>
                                <Link to={footer.conditions.url}>{footer.conditions.name}</Link> | <Link to={footer.privacy.url}>{footer.privacy.name}</Link>
                            </p>

                            <ul className="list-unstyled list-inline list-social mt-3 mb-6 mb-md-0">
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.facebook.com/tecalis/" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-facebook"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://twitter.com/tecalis" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                        <i className="fe fe-twitter"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.linkedin.com/company/tecalis/" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                        <i className="fe fe-linkedin"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.instagram.com/tecalis/" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-instagram"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item">
                                    <OutboundLink href="https://www.youtube.com/c/Tecalis" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-youtube"/>
                                    </OutboundLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-12 col-lg">
                            <ul className="iso text-center text-sm-left text-lg-right">
                                <li className="list-inline-item list-social-item mr-1">
                                    <StaticImage src="../img/ic_webinar_eideas.svg" width={110} height={110} alt="eidas"/>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <StaticImage src="../img/ic_webinar_cer_9001.svg" width={110} height={110} alt="cer9001"/>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=199259" rel="noopener noreferrer" target="_blank">
                                        <StaticImage src="../img/ic_webinar_iso_9001.svg" width={110} height={110} alt="iso9001"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=207780" target="_blank" rel="noreferrer">
                                        <StaticImage src="../img/ic_webinar_iso_27001.svg" width={110} height={110} alt="iso27001"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item">
                                    <StaticImage src="../img/ic_webinar_fido.svg" width={110} height={110} alt="FIDO"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

        </LayoutFourth>
    )
}

export default WebinarPage

export const webinarPageQuery = graphql
    `query($id: String!, $lang: String) {
    strapiWebinar(id: {eq: $id}) {
        seo {
            title
            meta_description
        }
        name
        recorded_text
        date(formatString: "MMMM DD, YYYY", locale: $lang)
        time_start
        time_end
        title
        description
        photo {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 576
                        placeholder: BLURRED
                        formats: [WEBP]
                    )
                }
            }
        }
        cta_title
        cta_description
        cta_button
        url
        is_finished
        exhibitors {
            first_name
            last_name
            photo {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 154
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
        }
        hubspot_webinar_name
        hubspot_form_registration {
            region
            portal_id
            form_id
        }
        hubspot_form_recorded {
            region
            portal_id
            form_id
        }
    }
    footer: markdownRemark (
        frontmatter: {
            name: { eq: "webinar-footer" }
        }
    ) {
        frontmatter {
            en {
                copyright
                conditions {
                    url
                    name
                }
                privacy {
                    url
                    name
                }
            }
            es {
                copyright
                conditions {
                    url
                    name
                }
                privacy {
                    url
                    name
                }
            }
        }
    }
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: { eq: "cookies" }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
